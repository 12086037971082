<template>
  <auth-form-wrapper
    :title="$t('auth.reset-password.title')"
    :subtitle="$t('auth.reset-password.subtitle')"
    :error="error"
  >
    <b-form class="auth-form mt-2" @submit.prevent="handleSubmit">
      <!-- Password -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.reset-password.form.password.label') }}
        </label>
        <div class="input-group input-group-merge">
          <b-form-input
            id="reset-password"
            v-model="formData.password"
            :type="passwordFieldType"
            name="password"
            :placeholder="$t('auth.reset-password.form.password.placeholder')"
            class="form-control-merge"
            required
          />
          <div class="input-group-append">
            <div class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
              <feather-icon
                :icon="passwordToggleIcon"
                size="16"
                class="toggle-password-icon"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Repeat Password -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.reset-password.form.password-confirm.label') }}
        </label>
        <div class="input-group input-group-merge">
          <b-form-input
            id="reset-password-confirm"
            v-model="formData.repeatPassword"
            :type="passwordFieldType"
            name="repeatPassword"
            :placeholder="$t('auth.reset-password.form.password-confirm.placeholder')"
            class="form-control-merge"
            required
          />
          <div class="input-group-append">
            <div class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
              <feather-icon
                :icon="passwordToggleIcon"
                size="16"
                class="toggle-password-icon"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="auth-btn-container d-flex justify-content-center">
        <b-button
          type="submit"
          variant="primary"
          class="auth-btn auth-btn-primary mt-2 mb-2"
          :disabled="invalid"
        >
          {{ $t('auth.reset-password.form.action.submit') }}
        </b-button>
      </div>
    </b-form>

    <!-- Cancel Link -->
    <p class="text-center mt-2">
      <b-link class="auth-link" :to="{ name: 'auth-login' }">
        {{ $t('common.cancel') }}
      </b-link>
    </p>
  </auth-form-wrapper>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import AuthFormWrapper from '@/auth/components/AuthFormWrapper.vue'

export default {
  name: 'ResetPassword',

  components: {
    AuthFormWrapper
  },

  inject: ['authApiUrl'],

  mixins: [togglePasswordVisibility],

  data() {
    return {
      formData: {
        password: '',
        repeatPassword: ''
      },
      error: null,
      invalid: false
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    communitySlug() {
      return this.$route.params.communityId
    },
    token() {
      return this.$route.query.token
    }
  },

  watch: {
    'formData.password'(newVal) {
      this.validateForm()
    },
    'formData.repeatPassword'(newVal) {
      this.validateForm()
    }
  },

  methods: {
    validateForm() {
      this.invalid = !this.formData.password || 
                    !this.formData.repeatPassword ||
                    this.formData.password.length < 6 ||
                    this.formData.password !== this.formData.repeatPassword
    },

    async handleSubmit() {
      if (this.formData.password !== this.formData.repeatPassword) {
        this.error = 'auth.reset-password.error.password-mismatch'
        return
      }

      const endpoint = this.authApiUrl + 'recoverpassword';
      try {
        const response = await fetch(
          endpoint,
          {
            method: 'POST',
            body: JSON.stringify({
              ...this.formData,
              token: this.token,
              locale: this.$store.state.locale.currentLocale,
              communitySlug: this.communitySlug
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        if (!response.ok) {
          throw new Error()
        }

        // Redirect to login
        this.$router.push({
          name: 'auth-login',
          params: { 
            communityId: this.communitySlug
          }
        })
      } catch (error) {
        console.error('Error:', error)
        this.error = 'auth.reset-password.error.general'
      }
    }
  }
}
</script>
